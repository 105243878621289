import { useMutation, useQuery, useQueryClient } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { HttpClientError, IDefaultResponseMessage, IProjectBillingCycle, QueryKeys } from '@nxs/models';

const BASE_URL = '/v1/project-billing-cycles';

export const useGetProjectBillingCycles = (projectId: string) => {
    const { GET } = useHttpClient();

    return useQuery<IProjectBillingCycle[], HttpClientError>([QueryKeys.ProjectBillingCycles, projectId], () => {
        return GET<IProjectBillingCycle[]>(`${BASE_URL}/${projectId}`);
    });
}

export const useGetBillingCycleProjects = (billingCycle: number) => {
    const { GET } = useHttpClient();

    return useQuery<IProjectBillingCycle[], HttpClientError>([QueryKeys.BillingCycleProjects, billingCycle], () => {
        return GET<IProjectBillingCycle[]>(`${BASE_URL}/${billingCycle}/projects`);
    }, {
        enabled: !!billingCycle && billingCycle > 0
    });
}

interface IRefreshProjectBillingCycles {
    projectId: string;
}
export const useRefreshProjectBillingCycles = () => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refreshCycles = (request: IRefreshProjectBillingCycles) => {
        return POST<IDefaultResponseMessage>(`${BASE_URL}/${request.projectId}/refresh`);
    }

    return useMutation<IDefaultResponseMessage, HttpClientError, IRefreshProjectBillingCycles>(refreshCycles, {
        onSuccess: (_, request) => {
            showSnackbar('Project billing cycles successfully refreshed');
            queryClient.invalidateQueries([QueryKeys.ProjectBillingCycles, request.projectId]);
        }
    });
}