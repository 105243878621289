import React from 'react';
import { AuthContext, AppSettingsContext, HttpClientContext, SnackbarContext, ConfirmContext } from '@nxs/contexts';

export * from './useScreenSize';
export * from './useBinaryToggle';

export const useAuth = () => React.useContext(AuthContext);
export const useAppSettings = () => React.useContext(AppSettingsContext);
export const useHttpClient = () => React.useContext(HttpClientContext);
export const useSnackbar = () => React.useContext(SnackbarContext);
export const useConfirm = () => React.useContext(ConfirmContext);