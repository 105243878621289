import { FunctionComponent } from 'react';

import { Stack, Box, Typography, SxProps, Theme } from '@mui/material';

import { TColor } from '@nxs/models';
import { useScreenSize } from '@nxs/hooks';
import { getColor } from '@nxs/utils';

import { ProgressSpinner } from './ProgressSpinner';

interface IBillboardProps {
    description: string;
    title: string;
    color: TColor;
    isLoading?: boolean;
    sx?: SxProps<Theme>;
}
export const Billboard: FunctionComponent<IBillboardProps> = (props: IBillboardProps) => {

    const { isMobile } = useScreenSize();

    const {
        description,
        title,
        color,
        isLoading,
        sx
    } = props;

    return (
        <Box
            display='flex'
            flexDirection='column'
            sx={{
                padding: isMobile ? '10px' : '20px',
                backgroundColor: getColor(color),
                borderRadius: '10px',
                ...(sx ?? {})
            }}
        >
            {
                isLoading ? (
                    <Stack justifyContent='center' alignItems='center' flex={1} padding='16px'>
                        <ProgressSpinner size={30} />
                    </Stack>
                ) : (
                    <>
                        <Typography variant='body2'>{description}</Typography>
                        <Typography fontWeight='bold' variant={isMobile ? 'h5' : 'h4'}>{title}</Typography>
                    </>
                )
            }
        </Box>
    )
}