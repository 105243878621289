import { LineChart as RechartsLineChart, Line as RechartsLine } from 'recharts';

import { getColor } from '@nxs/utils';
import { IGenericChartProps } from './models';

export function LineChart<T>(props: IGenericChartProps<T>) {

    const {
        data,
        width,
        height,
        children,
        dataKeys,
        disabledKeys
    } = props;

    return (
        <RechartsLineChart
            data={data}
            width={width}
            height={height}
            className='chart'
            margin={{ left: -20 }}
        >
            {children}
            {
                dataKeys
                    .filter(x => disabledKeys.indexOf(x.dataKey) < 0)
                    .map(dataKey => (
                        <RechartsLine
                            key={dataKey.dataKey as any}
                            type="monotone"
                            name={dataKey.legendName}
                            dataKey={dataKey.dataKey as any}
                            stroke={getColor(dataKey.color)}
                            dot={false}
                            activeDot={{ r: 3 }}
                            id={dataKey.dataKey as any}
                            animationDuration={500}
                            animationBegin={0}
                        />
                    ))
            }
        </RechartsLineChart>
    )
};