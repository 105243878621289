import { Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IStackedMenuItemProps {
    title: string;
    imageUrl?: string;
    subTitle?: string;
    direction?: 'column' | 'row'
}
export const StackedMenuItem: FunctionComponent<IStackedMenuItemProps> = (props: IStackedMenuItemProps) => {

    const { title, imageUrl, subTitle, direction = 'column' } = props;

    return (
        <Stack display='flex' flexDirection={direction} rowGap={1} columnGap={3} alignItems={direction === 'column' ? 'flex-start' : 'center'}>
            {
                imageUrl && (
                    <img src={imageUrl} alt={title} height={30} />
                )
            }
            
            <Typography>{title}</Typography>

            {
                subTitle && (
                    <Typography variant='caption'>{subTitle}</Typography>
                )
            }
        </Stack>
    )
}