import { FunctionComponent } from 'react';

import {
    IconButton as MuiIconButton,
    IconButtonProps,
} from '@mui/material';

import { ProgressSpinner } from './ProgressSpinner';

interface IIconButtonProps extends IconButtonProps {
    loading?: boolean;

    icon?: JSX.Element;
}
export const IconButton: FunctionComponent<IIconButtonProps> = (props: IIconButtonProps) => {
    const { loading, icon } = props;

    const baseButtonProps = {...props};
    delete baseButtonProps.loading;
    delete baseButtonProps.icon;

    const iconToRender = loading
        ? (<ProgressSpinner size={20} />)
        : icon;

    return (
        <MuiIconButton
            {...baseButtonProps}
            disabled={props.disabled || loading}
        >
            {iconToRender}
        </MuiIconButton>
    )
};