import React, { FunctionComponent, useReducer, useEffect } from 'react';

import { useGetSelf } from '@nxs/hooks/api';
import { IPortalUser, IReducerAction } from '@nxs/models';

type TAuthContextActions = 'SET_USER' | 'SET_INITIALIZED';

interface IAuthContext {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user?: IPortalUser;
}
const initialAuthState: IAuthContext = {
    isAuthenticated: false,
    isInitialized: false
};
export const AuthContext = React.createContext(initialAuthState);

const AuthContextReducer = (state: IAuthContext, action: IReducerAction<TAuthContextActions>): IAuthContext => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, isAuthenticated: !!action.payload, user: action.payload };
        case 'SET_INITIALIZED':
            return { ...state, isInitialized: action.payload };
        default:
            return state;
    }
}

interface IAuthContextProviderProps {
    children: any;
}
export const AuthContextProvider: FunctionComponent<IAuthContextProviderProps> = (props: IAuthContextProviderProps) => {
    const [state, dispatch] = useReducer(AuthContextReducer, {...initialAuthState});

    const { data: getSelfResponse, isFetched: isInitialized } = useGetSelf({ autoSignOut: false });

    useEffect(() => {        
        dispatch({
            type: 'SET_USER',
            payload: getSelfResponse
        });
    }, [getSelfResponse]);

    useEffect(() => {        
        dispatch({
            type: 'SET_INITIALIZED',
            payload: isInitialized
        });
    }, [isInitialized]);

    return (
        <AuthContext.Provider value={state}>
            {props.children}
        </AuthContext.Provider>
    )
};