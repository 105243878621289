import { FunctionComponent } from 'react';

import { CardHeader as MuiCardHeader, CardHeaderProps as MuiCardHeaderProps, Stack } from '@mui/material';

import { useScreenSize } from '@nxs/hooks';

interface ICardHeaderProps extends Omit<MuiCardHeaderProps, 'title'> {
    title: string;
    actions?: React.ReactNode;
}
export const CardHeader: FunctionComponent<ICardHeaderProps> = (props: ICardHeaderProps) => {

    const { title, actions } = props;

    const { isMobile } = useScreenSize();

    return (
        <MuiCardHeader
            {...props}
            title={!actions ? title : (
                <Stack direction={isMobile ? 'column' : 'row'} rowGap={2} columnGap={3} alignItems={isMobile ? 'flex-start' : 'center'}>
                    {title}

                    <Stack direction={isMobile ? 'column' : 'row'} columnGap={2} rowGap={1} flex={1} justifyContent='flex-end' alignItems='center' sx={{
                        width: isMobile ? '100%' : undefined
                    }}>
                        {actions}
                    </Stack>
                </Stack>
            )}
        />
    );
}