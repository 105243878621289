import React, { FunctionComponent, useReducer } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { IReducerAction } from '@nxs/models';
import { Button } from '@nxs/components';

type TSnackbarActions = 'OPEN' | 'CLOSE';

interface IConfirmConfig {
    title?: string;
    message: string;
    onPositive?: () => void | Promise<void>;
    positiveText?: string;
    positiveOnly?: boolean;
    onNegative?: () => void | Promise<void>;
    negativeText?: string;
}

interface IConfirmContext {
    confirm: (config: IConfirmConfig) => void;
    isOpen: boolean;
    config: IConfirmConfig | null;
}

const initialState: IConfirmContext = {
    confirm: () => null,
    isOpen: false,
    config: null
};
export const ConfirmContext = React.createContext<IConfirmContext>(initialState);

const ConfirmContextReducer = (state: IConfirmContext, action: IReducerAction<TSnackbarActions>): IConfirmContext => {
    switch (action.type) {
        case 'OPEN':
            return {...state, isOpen: true, config: action.payload};
        case 'CLOSE':
            return {...state, isOpen: false, config: null};
        default:
            return state;
    }
};

interface IConfirmProviderProps {
    children: any;
};
export const ConfirmContextProvider: FunctionComponent<IConfirmProviderProps> = (props: IConfirmProviderProps) => {
    const [state, dispatch] = useReducer(ConfirmContextReducer, {...initialState,
        confirm: (config: IConfirmConfig) => {
            dispatch({
                type: 'OPEN',
                payload: config
            });
        }
    });

    const handleNegativeClose = async () => {
        if (state.config?.onNegative) {
            await Promise.resolve(state.config.onNegative());
        }
        dispatch({ type: 'CLOSE' });
    }

    const handlePositive = async () => {
        if (state.config?.onPositive) {
            await Promise.resolve(state.config.onPositive());
        }
        dispatch({type: 'CLOSE'});
    }

    return (
        <ConfirmContext.Provider value={state}>
            {props.children}

            <Dialog
                open={state.isOpen}
                onClose={handleNegativeClose}
            >
                <DialogTitle>{state.config?.title ?? 'Are you sure?'}</DialogTitle>
                <DialogContent>
                    {state.config?.message}
                </DialogContent>
                <DialogActions>
                    {
                        !(state.config?.positiveOnly) && (
                            <Button
                                variant='text'
                                color='error'
                                type='button'
                                onClick={handleNegativeClose}
                            >
                                {state.config?.negativeText ?? 'Cancel'}
                            </Button>
                        )
                    }

                    <Button
                        variant='text'
                        type='button'
                        onClick={handlePositive}
                    >
                        {state.config?.positiveText ?? 'Continue'}
                    </Button>
                </DialogActions>
            </Dialog>
        </ConfirmContext.Provider>
    );
}