export enum QueryKeys {
    AppSettings = 'AppSettingsQueryKey',
    Self = 'SelfQueryKey',
    XeroIsConfigured = 'XeroIsConfiguredQueryKey',
    XeroAvailableClients = 'XeroAvailableClientsQueryKey',
    XeroInvoiceItemCodes = 'XeroInvoiceItemCodesQueryKey',
    Clients = 'ClientsQueryKey',
    Projects = 'ProjectsQueryKey',
    ProjectWebHookSubscriptions = 'ProjectWebHookSubscriptionsQueryKey',
    AzureDevOpsAvailableProjects = 'AzureDevOpsAvailableProjectsQueryKey',
    AzureDevOpsIsConfigured = 'AzureDevOpsIsConfiguredQueryKey',
    ProjectBillingCycles = 'ProjectBillingCyclesQueryKey',
    BillingCycleProjects = 'BillingCycleProjectsQueryKey',
    ProjectTasks = 'ProjectTasksQueryKey',
    AvailableProjectTeamMembers = 'AvailableProjectTeamMembersQueryKey',
    ProjectTeamMembers = 'ProjectTeamMembersQueryKey',
    ClientInvoices = 'ClientInvoicesQueryKey',
    RecurringExpenses = 'RecurringExpensesQueryKey',
    TravelLogEntries = 'TravelLogEntriesQueryKey',
}