import { TDateFormat } from "@nxs/models";
import dayjs, { isDayjs } from "dayjs";

export const formatDate = (value: any, outputFormat: TDateFormat): string => {
    if (!value) {
        return '-';
    }
    
    const format = outputFormat === 'datetime' ? 'D MMM YYYY - HH:mm:ss' : 'D MMM YYYY';

    if (isDayjs(value)) {
        return value.format(format);
    }

    return dayjs(value).format(format);
}

/**
 * Gets the billing cycle. Defaults to current cycle if modifier is not provided
 * @param modifier Amount of months to add to the billing cycle
 * @returns billingCycle
 */
export const getBillingCycle = (modifier: number = 0): number => {
    const currentDate = dayjs();
    const billingMonthModifier = currentDate.date() > 16 ? 1 : 0;

    let billingCycleDate = currentDate.startOf('month').add(billingMonthModifier, 'month');
    if (modifier !== 0) {
        billingCycleDate = billingCycleDate.add(modifier, 'months');
    }
    return parseInt(billingCycleDate.format('YYYYMM'));
}

export const formatDayOfMonth = (dayOfMonth: number): string => {
    let suffix = 'th';
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
        suffix = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
        suffix = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
        suffix = 'rd'
    }

    return `${dayOfMonth}${suffix}`;
}