import React from 'react';

interface IFormContext<T> {
    formData: T;
    formErrors: Partial<Record<keyof T, boolean>>;
    disabled: boolean;
    handleFormChange: (name: keyof T, value: any) => void;
}
export const FormContext = React.createContext<IFormContext<any>>({
    formData: {},
    formErrors: {},
    disabled: false,
    handleFormChange: () => null
})


export function useFormContext<T>(): IFormContext<T> {
    return React.useContext(FormContext) as IFormContext<T>;
}