import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { QueryKeys, IProject, IUpdateProjectRequest, ICreateProjectRequest, HttpClientError, IProjectWebHookSubscription } from '@nxs/models';

export const useGetProject = (projectId: string) => {
    const { GET } = useHttpClient();

    return useQuery<IProject, HttpClientError>([QueryKeys.Projects, projectId], () => {
        return GET<IProject>(`/v1/projects/${projectId}`);
    });
}

export const useGetProjects = (clientId?: string) => {
    const { GET } = useHttpClient();

    return useQuery<IProject[], HttpClientError>([QueryKeys.Projects, clientId], () => {
        return GET<IProject[]>(`/v1/projects`, { clientId });
    });
}

export const useGetProjectWebHookSubscriptions = (projectId?: string) => {
    const { GET } = useHttpClient();

    return useQuery<IProjectWebHookSubscription[], HttpClientError>([QueryKeys.ProjectWebHookSubscriptions, projectId], () => {
        return GET<IProjectWebHookSubscription[]>(`/v1/projects/${projectId}/webhook-subscriptions`);
    }, {
        enabled: !!projectId
    });
}

export const useReCreateProjectWebHookSubscriptionsMutation = () => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const reCreateWebHookSubscriptions = (projectId: string) => {
        return POST<IProjectWebHookSubscription[]>(`/v1/projects/${projectId}/webhook-subscriptions/re-create`);
    };

    return useMutation<IProjectWebHookSubscription[], HttpClientError, string>(reCreateWebHookSubscriptions, {
        onSuccess: (_, projectId) => {
            showSnackbar('WebHook subscriptions successfully re-created');
            queryClient.invalidateQueries([QueryKeys.ProjectWebHookSubscriptions, projectId]);
        }
    });
}

export const useUpdateProjectMutation = () => {
    const { PUT } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const updateProject = (request: IUpdateProjectRequest) => {
        return PUT<IProject>(`/v1/projects/${request.id}`, request);
    };

    return useMutation<IProject, HttpClientError, IUpdateProjectRequest>(updateProject, {
        onSuccess: (project) => {
            showSnackbar('Project successfully updated');
            queryClient.invalidateQueries([QueryKeys.Projects]);
            queryClient.invalidateQueries([QueryKeys.Projects, project.id]);
            queryClient.invalidateQueries([QueryKeys.Clients, project.client?.id]);
        }
    });
}

export const useCreateProjectMutation = (autoNavigate?: boolean) => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const createProject = (request: ICreateProjectRequest) => {
        return POST<IProject>('/v1/projects', request);
    };

    return useMutation<IProject, HttpClientError, ICreateProjectRequest>(createProject, {
        onSuccess: (project) => {
            showSnackbar('Project successfully created');
            if (autoNavigate) {
                navigate(`/dashboard/projects/${project.id}`);
            }
            queryClient.invalidateQueries([QueryKeys.Projects]);
            queryClient.invalidateQueries([QueryKeys.Clients, project.client?.id]);
        }
    });
}