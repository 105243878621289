import React, { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  Box as MuiBox,
  Collapse as MuiCollapse
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, SubdirectoryArrowRight } from "@mui/icons-material";

import { ITableChildTable, TableColumn } from "./models";
import { Table } from "./Table";
import { camelCaseToUserText, formatAsDisplayType, getColor } from "@nxs/utils";
import { IconButton, Checkbox } from "@nxs/components";

interface IDataTableRowProps<T, TChild> {
  row: T;
  columns: TableColumn<T>[];
  getRowClickRoute?: (row: T) => string;
  childTable?: ITableChildTable<T, TChild>;
}
export function DataTableRow<T, TChild>(props: IDataTableRowProps<T, TChild>) {

    const { row, columns, getRowClickRoute, childTable } = props;

    const [isChildTableExpanded, setIsChildTableExpanded] = useState(false);

    const getRowColumnValue = (row: T, column: TableColumn<T>): React.ReactNode => {
        if (column.element) {
            return column.element(row);
        } else if (!column.key) {
            return '';
        }

        const columnValue = row[column.key] as any;
        if (columnValue === '' || columnValue === null || columnValue === undefined) {
            return '';
        } else if (column.displayType === 'checkbox') {
            return <Checkbox readOnly checked={columnValue} />
        } else if (column.displayType) {
            return formatAsDisplayType(columnValue, column.displayType);
        }

        return columnValue as any;
  };

  return (
      <React.Fragment>
          <MuiTableRow
              hover
              sx={{cursor: 'pointer'}}
          >
              {
                  columns.map((column, index) => (
                      <MuiTableCell
                        key={index}
                        sx={{
                            position: 'relative',
                            maxWidth: column.maxWidth,
                            whiteSpace: 'normal',
                            color: column.textColor ? getColor(column.textColor) : undefined
                        }}
                        onClick={() => !getRowClickRoute && !!childTable ? setIsChildTableExpanded(!isChildTableExpanded) : null}
                    >
                          {getRowColumnValue(row, column)}
                          {
                              getRowClickRoute && !column.disableRowClick && (
                                  <RouterLink to={getRowClickRoute(row)} className='absolute-fill' />
                              )
                          }
                      </MuiTableCell>
                  ))
              }
              {
                  childTable && (
                      <MuiTableCell>
                          <IconButton
                              icon={isChildTableExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              size='small'
                              onClick={() => setIsChildTableExpanded(!isChildTableExpanded)}
                          />
                      </MuiTableCell>
                  )
              }
          </MuiTableRow>
          {
              childTable && (
                  <MuiTableRow>
                      <MuiTableCell sx={{ paddingBottom: 0, paddingTop: 0, verticalAlign: 'top' }}>
                          <MuiCollapse in={isChildTableExpanded} timeout='auto' unmountOnExit>
                              <MuiBox sx={{marginTop: 2}}>
                                  <SubdirectoryArrowRight />
                              </MuiBox>
                          </MuiCollapse>
                      </MuiTableCell>
                      <MuiTableCell
                          colSpan={columns.length}
                          sx={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                          <MuiCollapse in={isChildTableExpanded} timeout='auto' unmountOnExit>
                              <MuiBox sx={{marginTop: 2}}>
                                  <MuiTypography variant='h6'>{childTable.label ?? camelCaseToUserText(childTable.childDataKey as string)}</MuiTypography>
                                  <Table
                                      {...childTable.tableProps}
                                      data={row[childTable.childDataKey] as any[]}
                                      noWrapperComponent
                                      noPagination
                                  />
                              </MuiBox>
                          </MuiCollapse>
                      </MuiTableCell>
                  </MuiTableRow>
              )
          }
      </React.Fragment>
  )
}