import { useState, useEffect } from 'react';

import {
    TableContainer as MuiTableContainer,
    Table as MuiTable,
    TableHead as MuiTableHead,
    TableRow as MuiTableRow,
    TableCell as MuiTableCell,
    TableBody as MuiTableBody,
    TableFooter as MuiTableFooter,
    TablePagination as MuiTablePagination,
    Paper as MuiPaper,
    Typography as MuiTypography,
    Stack,
    Typography,
    SxProps,
    Theme
} from '@mui/material';

import { camelCaseToUserText, getColor } from '@nxs/utils';
import { useScreenSize } from '@nxs/hooks';
import { NonEditableFields, INonEditableRow } from '@nxs/components';

import { ITableProps, TableColumn } from './models';
import { DataTableRow } from './DataTableRow';
import { NoDataTableRow } from './NoDataTableRow';

const tablePageSizes: number[] = [10, 25, 50];

export function Table<T, TChild = any>(props: ITableProps<T, TChild>) {

    const { isMobile } = useScreenSize();

    const {
        data,
        columns,
        keyAttribute,
        getRowClickRoute,
        noWrapperComponent = false,
        childTable,
        noPagination
    } = props;

    const [currentPageSize, setCurrentPageSize] = useState(tablePageSizes[0]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [pagedData, setPagedData] = useState<T[]>([]);

    const getColumnLabel = (column: TableColumn<T>): string => {
        return column.label || camelCaseToUserText(column.key as string);
    }

    useEffect(() => {
        let startIndex: number;
        let endIndex: number;
        let newPageIndex = currentPageIndex;
        do {
            startIndex = currentPageSize * newPageIndex;
            endIndex = Math.min(startIndex + currentPageSize, data.length);

            if (data.length > 0 && startIndex >= data.length) {
                newPageIndex--;
            }
        } while (data.length > 0 && startIndex >= data.length);

        setPagedData(data.slice(startIndex, endIndex));
        if (newPageIndex !== currentPageIndex) {
            setCurrentPageIndex(newPageIndex);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageSize, currentPageIndex, data]);

    const TablePagination = ({ sx }: { sx?: SxProps<Theme> }) => (
        <MuiTablePagination
            rowsPerPageOptions={tablePageSizes}
            colSpan={columns.length + (childTable ? 1 : 0)}
            count={data.length}
            rowsPerPage={currentPageSize}
            page={currentPageIndex}
            onPageChange={(_, newPage: number) => setCurrentPageIndex(newPage)}
            onRowsPerPageChange={(e) => setCurrentPageSize(parseInt(e.target.value, 10))}
            sx={sx}
        />
    );

    const MobileTable = () => {

        const nonEditableRows: INonEditableRow<T>[] = columns.map((column) => ({
            dataKey: column.key,
            displayType: column.displayType,
            label: getColumnLabel(column),
            element: column.element
        }));

        return (
            <MuiPaper>
                <Stack direction='column'>
                    {
                        data?.length > 0 ? (
                            pagedData.map((row) => (
                                <NonEditableFields
                                    data={row}
                                    key={row[keyAttribute] as any}
                                    rowBorders={false}
                                    rows={nonEditableRows}
                                    sx={{
                                        padding: '10px 20px',
                                        borderBottom: 'var(--border-light)'
                                    }}
                                />
                            ))
                        ) : (
                            <Typography textAlign='center' padding='30px'>
                                <i>No data available...</i>
                            </Typography>
                        )
                    }

                    {
                        !noPagination && data?.length > 0 && (
                            <TablePagination sx={{
                                borderBottom: 'none'
                            }} />
                        )
                    }
                </Stack>
            </MuiPaper>
        );
    }

    const tableRoot = (
        <MuiTable stickyHeader={true} sx={{width: '100%'}}>
            <MuiTableHead sx={{
                '.MuiTableCell-stickyHeader': {
                    background: 'none',
                },
                borderBottomColor: 'black',
            }}>
                <MuiTableRow>
                    {
                        columns.map((column, index) => (
                            <MuiTableCell key={index} sx={{
                                padding: '30px 16px',
                                color: column.textColor ? getColor(column.textColor) : undefined
                            }}>
                                <MuiTypography variant='body2' fontWeight='bold'>
                                    {getColumnLabel(column)}
                                </MuiTypography>
                            </MuiTableCell>
                        ))
                    }
                    {
                        childTable && (
                            <MuiTableCell />
                        )
                    }
                </MuiTableRow>
            </MuiTableHead>

            <MuiTableBody>
                {
                    data?.length > 0 ? (
                        pagedData.map((row) => (
                            <DataTableRow
                                row={row}
                                columns={columns}
                                key={row[keyAttribute] as any}
                                getRowClickRoute={getRowClickRoute}
                                childTable={childTable}
                            />
                        ))
                    ) : <NoDataTableRow columnCount={columns.length} />
                }
            </MuiTableBody>

            {
                !noPagination && data?.length > 0 && (
                    <MuiTableFooter>
                        <MuiTableRow>
                            <TablePagination />
                        </MuiTableRow>
                    </MuiTableFooter>
                )
            }
        </MuiTable>
    );

    if (isMobile) {
        return <MobileTable />
    } else if (noWrapperComponent) {
        return tableRoot;
    }

    return (
        <MuiTableContainer component={MuiPaper} sx={{
            width: '100%'
        }}>
            {tableRoot}
        </MuiTableContainer>
    )
};

