import { FunctionComponent } from 'react';

import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel, CheckboxProps as MuiCheckboxProps } from '@mui/material';

interface ICheckboxProps extends MuiCheckboxProps {
    label?: string;
}
export const Checkbox: FunctionComponent<ICheckboxProps> = (props: ICheckboxProps) => {

    const { label } = props;
    const muiCheckboxProps = {...props};
    delete muiCheckboxProps.label;

    const checkboxElement = (
        <MuiCheckbox {...muiCheckboxProps} />
    );

    if (label) {
        return (
            <MuiFormControlLabel
                label={label}
                control={checkboxElement}
                required={muiCheckboxProps.required}
                disabled={muiCheckboxProps.disabled}
            />
        )
    }

    return checkboxElement;
}