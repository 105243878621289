import { useMutation, useQuery, useQueryClient } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { IRecurringExpense, ICreateOrUpdateRecurringExpenseRequest, QueryKeys, HttpClientError, IDefaultResponseMessage } from '@nxs/models';

const BASE_URL = '/v1/recurring-expenses';

export const useGetRecurringExpense = (id: string) => {
    const { GET } = useHttpClient();

    return useQuery<IRecurringExpense, HttpClientError>([QueryKeys.RecurringExpenses, id], () => {
        return GET<IRecurringExpense>(`${BASE_URL}/${id}`);
    }, {
        enabled: !!id
    });
}

export const useGetRecurringExpenses = () => {
    const { GET } = useHttpClient();

    return useQuery<IRecurringExpense[], HttpClientError>([QueryKeys.RecurringExpenses], () => {
        return GET<IRecurringExpense[]>(BASE_URL);
    });
}

interface IUpdateRecurringExpense {
    id: string;
    request: ICreateOrUpdateRecurringExpenseRequest;
}
export const useUpdateRecurringExpenseMutation = (onSuccess?: () => void) => {
    const { PUT } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const updateRecurringExpense = ({ id, request }: IUpdateRecurringExpense) => {
        return PUT<IRecurringExpense>(`${BASE_URL}/${id}`, request);
    };

    return useMutation<IRecurringExpense, HttpClientError, IUpdateRecurringExpense>(updateRecurringExpense, {
        onSuccess: (response) => {
            showSnackbar('Recurring Expense successfully updated');
            queryClient.invalidateQueries([QueryKeys.RecurringExpenses]);
            queryClient.invalidateQueries([QueryKeys.RecurringExpenses, response.id]);

            if (onSuccess) {
                onSuccess();
            }
        }
    });
}

export const useCreateRecurringExpenseMutation = (onSuccess?: () => void) => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const createRecurringExpense = (request: ICreateOrUpdateRecurringExpenseRequest) => {
        return POST<IRecurringExpense>(BASE_URL, request);
    };

    return useMutation<IRecurringExpense, HttpClientError, ICreateOrUpdateRecurringExpenseRequest>(createRecurringExpense, {
        onSuccess: () => {
            showSnackbar('Recurring Expense successfully created');
            queryClient.invalidateQueries([QueryKeys.RecurringExpenses]);

            if (onSuccess) {
                onSuccess();
            }
        }
    });
}

export const useDeleteRecurringExpenseMutation = () => {
    const { DELETE } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const deleteRecurringExpense = (id: string) => {
        return DELETE<IDefaultResponseMessage>(`${BASE_URL}/${id}`);
    };

    return useMutation<IDefaultResponseMessage, HttpClientError, string>(deleteRecurringExpense, {
        onSuccess: (_, inputId) => {
            showSnackbar('Recurring Expense successfully deleted');
            queryClient.invalidateQueries([QueryKeys.RecurringExpenses]);
            queryClient.invalidateQueries([QueryKeys.RecurringExpenses, inputId]);
        }
    });
}