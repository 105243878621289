import { TColor } from "@nxs/models";

export const getColor = (val: TColor | string): string =>
    val.startsWith('#') || val.startsWith('rgb') ? val : `var(--color-${val})`;



export const getOpagueColor = (val: TColor | string, opacity: number) => {
    if (!val.startsWith('#') && !val.startsWith('rgb')) {
        return `rgba(var(--color-${val}-rgb), ${opacity})`;
    }

    let rgbComponents: { r: number, g: number, b: number } = {
        r: 0,
        b: 0,
        g: 0
    };

    if (val.startsWith('#')) {
        const hexRgbMatch = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(val);
        if (hexRgbMatch) {
            rgbComponents = {
                r: parseInt(hexRgbMatch[1], 16),
                g: parseInt(hexRgbMatch[2], 16),
                b: parseInt(hexRgbMatch[3], 16)
            }
        }
    } else if (val.startsWith('rgb')) {
        const rgbParts = val
            .replaceAll(' ', '')
            .replace('rgba', '')
            .replace('rgb', '')
            .replace('(', '')
            .replace(')', '')
            .split(',')
            .map(x => parseInt(x, 16));

        rgbComponents = {
            r: rgbParts[0],
            g: rgbParts[1],
            b: rgbParts[2]
        };
    }

    return `rgba(${rgbComponents.r}, ${rgbComponents.g}, ${rgbComponents.b}, ${opacity})`;
}