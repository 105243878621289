const nzdCurrencyFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD'
});
const nzdMinimalCurrencyFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

export const formatAsCurrency = (value: string | number, style?: 'normal' | 'minimal'): string => {
    let valueToUse: number;
    if (!value) {
        return '-'
    } else if (typeof value === 'string') {
        valueToUse = parseFloat(value);
    } else {
        valueToUse = value as number;
    }

    return style === 'minimal' ? nzdMinimalCurrencyFormatter.format(valueToUse) : nzdCurrencyFormatter.format(valueToUse);
}