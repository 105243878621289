import { useMutation, useQuery, useQueryClient } from "react-query";

import { ILoginRequest, IPortalUser, QueryKeys, HttpClientError, IDefaultResponseMessage } from '@nxs/models';
import { useHttpClient, useSnackbar } from '@nxs/hooks';

export const useGetSelf = (props?: { autoSignOut?: boolean }) => {
    const { GET } = useHttpClient();
    const { autoSignOut = true } = props ?? {};

    return useQuery<IPortalUser, HttpClientError>([QueryKeys.Self], () => {
        return GET<IPortalUser>('/v1/auth/self', undefined, {
            autoLogOutOnAuthError: autoSignOut,
            autoShowErrorMessages: autoSignOut
        });
    });
}

export const useLogoutMutation = () => {
    const { POST } = useHttpClient();
    const queryClient = useQueryClient();

    const logoutUser = async () => {
        return POST<IDefaultResponseMessage>('/v1/auth/logout');
    }
    return useMutation<IDefaultResponseMessage, HttpClientError>(logoutUser, {
        onSuccess: () => {
            queryClient.resetQueries([QueryKeys.Self]);
        }
    })
}

export const useLoginMutation = () => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const loginUser = async (val: ILoginRequest) => {
        return POST<IPortalUser>('/v1/auth/login', val);
    }
    return useMutation<IPortalUser, HttpClientError, ILoginRequest>(loginUser, {
        onSuccess: (portalUser) => {
            queryClient.resetQueries([QueryKeys.Self]);
            showSnackbar(`Welcome back ${portalUser.name}`);
        }
    })
}