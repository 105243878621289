import { useMutation, useQuery, useQueryClient } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { QueryKeys, HttpClientError, IDefaultResponseMessage, ITravelLogEntry, ICreateOrUpdateTravelLogEntryRequest } from '@nxs/models';

const BASE_URL = '/v1/travel-log';
enum TravelLogQueryKeys {
    List = 'List'
};

export const useGetTravelLogEntry = (id: string) => {
    const { GET } = useHttpClient();

    return useQuery<ITravelLogEntry, HttpClientError>([QueryKeys.TravelLogEntries, id], () => {
        return GET<ITravelLogEntry>(`${BASE_URL}/${id}`);
    }, {
        enabled: !!id
    });
}

export const useGetTravelLogEntries = (request: { clientId?: string, financialYear?: number }) => {
    const { GET } = useHttpClient();

    return useQuery<ITravelLogEntry[], HttpClientError>([QueryKeys.TravelLogEntries, TravelLogQueryKeys.List, request], () => {
        return GET<ITravelLogEntry[]>(BASE_URL, request);
    });
}

interface IRequest<TBody> {
    id: string;
    body: TBody;
}
export const useUpdateTravelLogEntryMutation = (onSuccess?: () => void) => {
    const { PUT } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const updateTravelLogEntry = ({ id, body }: IRequest<ICreateOrUpdateTravelLogEntryRequest>) => {
        return PUT<ITravelLogEntry>(`${BASE_URL}/${id}`, body);
    };

    return useMutation<ITravelLogEntry, HttpClientError, IRequest<ICreateOrUpdateTravelLogEntryRequest>>(updateTravelLogEntry, {
        onSuccess: (response) => {
            showSnackbar('Travel Log Entry successfully updated');
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.TravelLogEntries, TravelLogQueryKeys.List]
            });
            queryClient.setQueryData([QueryKeys.TravelLogEntries, response.id], response);

            if (onSuccess) {
                onSuccess();
            }
        }
    });
}

export const useCreateTravelLogEntryMutation = (onSuccess?: () => void) => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const createTravelLogEntry = (request: ICreateOrUpdateTravelLogEntryRequest) => {
        return POST<ITravelLogEntry>(BASE_URL, request);
    };

    return useMutation<ITravelLogEntry, HttpClientError, ICreateOrUpdateTravelLogEntryRequest>(createTravelLogEntry, {
        onSuccess: (response) => {
            showSnackbar('Travel Log Entry successfully created');
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.TravelLogEntries, TravelLogQueryKeys.List]
            });
            queryClient.setQueryData([QueryKeys.TravelLogEntries, response.id], response);

            if (onSuccess) {
                onSuccess();
            }
        }
    });
}

export const useDeleteTravelLogEntryMutation = () => {
    const { DELETE } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const deleteTravelLogEntry = (id: string) => {
        return DELETE<IDefaultResponseMessage>(`${BASE_URL}/${id}`);
    };

    return useMutation<IDefaultResponseMessage, HttpClientError, string>(deleteTravelLogEntry, {
        onSuccess: (_, inputId) => {
            showSnackbar('Travel Log Entry successfully deleted');
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.TravelLogEntries, TravelLogQueryKeys.List]
            });
            queryClient.invalidateQueries([QueryKeys.TravelLogEntries, inputId]);
        }
    });
}