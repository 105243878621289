import { useMutation, useQuery, useQueryClient } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { HttpClientError, IAvailableProjectTeamMember, ICreateOrUpdateProjectTeamMemberRequest, IProjectTeamMember, QueryKeys } from '@nxs/models';
import { formatRoute } from "@nxs/utils";

const BASE_URL = '/v1/projects/:projectId/team-members';

export const useGetAvailableProjectTeamMembers = (projectId: string) => {
    const { GET } = useHttpClient();

    return useQuery<IAvailableProjectTeamMember[], HttpClientError>([QueryKeys.AvailableProjectTeamMembers, projectId], () => {
        return GET<IAvailableProjectTeamMember[]>(`${formatRoute(BASE_URL, { projectId })}/available`);
    }, {
        enabled: !!projectId
    });
}

export const useGetProjectTeamMembers = (projectId: string) => {
    const { GET } = useHttpClient();

    return useQuery<IProjectTeamMember[], HttpClientError>([QueryKeys.ProjectTeamMembers, projectId], () => {
        return GET<IProjectTeamMember[]>(`${formatRoute(BASE_URL, { projectId })}`);
    }, {
        enabled: !!projectId
    });
}

export const useCreateOrUpdateProjectTeamMemberMutation = (onSuccess?: () => void) => {
    const { PUT } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const createOrUpdateProjectTeamMember = ({ projectId, ...request }: ICreateOrUpdateProjectTeamMemberRequest) => {
        return PUT<IProjectTeamMember>(`${formatRoute(BASE_URL, { projectId })}`, request);
    };

    return useMutation<IProjectTeamMember, HttpClientError, ICreateOrUpdateProjectTeamMemberRequest>(createOrUpdateProjectTeamMember, {
        onSuccess: (response, input) => {
            showSnackbar('Project team members updated');
            queryClient.invalidateQueries([QueryKeys.ProjectTeamMembers, input.projectId]);

            if (onSuccess) {
                onSuccess();
            }
        }
    });
}