import React, { useEffect, useRef, useState } from 'react';
import './chart.scss';

import { Box, Stack, Typography, Tooltip as MuiTooltip } from '@mui/material';

import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';

import { TChartType, TColor } from '@nxs/models';
import { formatAsDisplayType, getColor } from '@nxs/utils';

import { LineChart } from './LineChart';
import { AreaChart } from './AreaChart';
import { BarChart } from './BarChart';
import { PieChart } from './PieChart';
import { IChartDataKey, IGenericChartProps, IReferenceLine } from './models';

import chartNoDataBackground from '@nxs/images/chart-no-data-background.webp';

interface IChartProps<T> {
    data: T[];
    type: TChartType;
    dataKeys: IChartDataKey<T>[];

    heightRatio?: number;
    fullHeight?: boolean;
    xAxisDataKey?: string;
    xAxisLabel?: (data: any) => string;
    yAxisDataKey?: string;
    referenceLines?: IReferenceLine[];
    hideGrid?: boolean;
    hideLegends?: boolean;
    chartColor?: TColor | string;
    legendsChanged?: (legends: string[]) => void;
    hideTooltip?: boolean;
}
export function Chart<T>(props: IChartProps<T>) {

    const [chartHeight, setChartHeight] = useState(0);
    const [chartWidth, setChartWidth] = useState(0);
    const [disabledKeys, setDisabledKeys] = useState<(keyof T)[]>([]);
    const lineChartWrapperRef = useRef<HTMLElement>();

    useEffect(() => {
        if (!lineChartWrapperRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            if (!lineChartWrapperRef.current) {
                return;
            }

            setChartWidth(lineChartWrapperRef.current.clientWidth);
            if (props.fullHeight) {
                setChartHeight(lineChartWrapperRef.current.clientHeight);
            } else {
                setChartHeight(lineChartWrapperRef.current.clientWidth * (props.heightRatio ?? 0.6));
            }
        });
        resizeObserver.observe(lineChartWrapperRef.current);
        
        return () => {
            resizeObserver.disconnect();
        }
    }, [lineChartWrapperRef, props.heightRatio, props.fullHeight]);

    const toggleLegend = (dataKey: keyof T) => {
        let disabledKeysClone = disabledKeys.splice(0);
        const index = disabledKeysClone.indexOf(dataKey);
        if (index >= 0) {
            disabledKeysClone.splice(index, 1);
        } else {
            disabledKeysClone.push(dataKey);
        }
        if (disabledKeysClone.length === props.dataKeys.length || (disabledKeysClone.length === props.dataKeys.length - 1 && props.type === 'pie')) {
            disabledKeysClone = [];
        }
        setDisabledKeys(disabledKeysClone);
        if (props.legendsChanged) {
            props.legendsChanged(props.dataKeys.filter(x => disabledKeysClone.indexOf(x.dataKey) < 0).map(x => x.legendName));
        }
    }

    const chartTooltip = (data: any) => {
        if (!data.active || !data.payload || data.payload.length <= 0) {
            return null;
        }

        const valueLabels: Array<{ heading: string, value: any, key: string, color: string }> = data.payload.map((payload: any) => {
            const dataKey = props.type === 'pie' ? payload.payload.key : payload.dataKey;
            const dataName = props.type === 'pie' ? payload.payload.label : payload.name;
            
            const targetDataKey = props.dataKeys.find(x => x.dataKey === dataKey);
            const displayType = targetDataKey?.displayType;
            const dataValue = formatAsDisplayType(props.type === 'pie' ? payload.payload.value : payload.payload[dataKey], displayType);

            return {
                heading: dataName,
                value: dataValue,
                key: dataKey,
                color: getColor(targetDataKey?.color ?? 'primary')
            };
        });

        return (
            <Stack className='chart-tooltip'>
                {
                    valueLabels.map((label, index) => (
                        <React.Fragment key={index}>
                            <Typography variant='caption'>{label.heading}</Typography>
                            <Typography key={label.key} color={label.color} variant='body2'>{label.value}</Typography>
                        </React.Fragment>
                    ))
                }
            </Stack>
        )
    };

    const chartLegend = (data: any): JSX.Element => {
        const { payload }: { payload: Array<{ dataKey: keyof T, color: TColor | string, value: string }> } = data;

        return (
            <Stack
                display='flex'
                flexDirection='row'
                flexWrap='nowrap'
                columnGap={4}
                justifyContent='flex-start'
                alignItems='center'
                className='chart-legends'
                sx={{
                    maxWidth: '100%',
                    overflow: 'auto'
                }}
            >
                {
                    payload.map(item => (
                        <MuiTooltip
                            key={item.dataKey as any}
                            title='Click to toggle'
                        >
                            <Stack
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                className={`legend-label ${disabledKeys.indexOf(item.dataKey) < 0 ? 'active' : 'inactive'}`}
                                onClick={() => toggleLegend(item.dataKey)}
                                columnGap={1}
                            >
                                <Box
                                    className='legend-dot'
                                    sx={{
                                        backgroundColor: getColor(item.color),
                                        borderColor: getColor(item.color),
                                        textDecoration: 'inherit',
                                        flexShrink: 0
                                    }}
                                />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        textDecoration: 'inherit',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {item.value}
                                </Typography>
                            </Stack>
                        </MuiTooltip>
                    ))
                }
            </Stack>
        )
    }

    const chartComponents = (
        <>
            {
                props.type !== 'pie' && (
                    <>
                        {
                            !props.hideGrid && (
                                <CartesianGrid strokeDasharray="3 3" />
                            )
                        }
                        <XAxis dataKey={props.xAxisDataKey ?? props.xAxisLabel} />
                        <YAxis
                            domain={[(dataMin: number) => Math.floor(dataMin - (dataMin * 0.05)), (dataMax: number) => dataMax < 1 && dataMax > -1 ? (Math.ceil(dataMax * 1000) / 1000) : Math.ceil(dataMax)]}
                            type='number'
                        />
                    </>
                )   
            }

            {
                !props.hideTooltip && (
                    <Tooltip
                        content={chartTooltip}
                        isAnimationActive={true}
                    />
                )
            }

            {
                !props.hideLegends && (
                    <Legend
                        formatter={(value, entry, index) => {
                            return props.type === 'pie' ? (entry.payload as any).label : entry.value;
                        }}
                        payload={props.dataKeys.map(x => ({
                            dataKey: x.dataKey as any,
                            color: x.color,
                            value: x.legendName
                        }))}
                        content={chartLegend}
                    />
                )
            }

            {
                props.referenceLines && props.referenceLines.length > 0 && props.referenceLines.map((line, index) => (
                    <ReferenceLine
                        key={index}
                        stroke={`var(--color-${line.color})`}
                        strokeWidth={1}
                        label={line.label}
                        x={line.x}
                        y={line.y}
                    />
                ))
            }
        </>
    );

    const getChart = () => {
        const chartProps: IGenericChartProps<T> = {
            data: props.data,
            dataKeys: props.dataKeys,
            height: chartHeight,
            width: chartWidth,
            children: chartComponents,
            disabledKeys: disabledKeys,
            chartColor: props.chartColor ?? 'background-lighter'
        };
        switch(props.type) {
            case 'line': return <LineChart {...chartProps} />;
            case 'area': return <AreaChart {...chartProps} />;
            case 'bar': return <BarChart {...chartProps} />;
            case 'pie': return <PieChart {...chartProps} />;
        }
    }

    return (
        <div ref={lineChartWrapperRef as any} className='chart-wrapper'>
            {
                props.data && props.data.length === 0 ? (
                    <Box
                        sx={{height: chartHeight, width: chartWidth}}
                        className='chart-no-data-wrapper'
                    >
                        <img
                            src={chartNoDataBackground}
                            alt='No data available'
                            className='absolute-fill' />
                        <Typography sx={{zIndex: '1'}} variant='h5' textAlign='center'>No data available</Typography>
                    </Box>
                ) : props.data?.length > 0 ? getChart() : null
            }
        </div>
    )
};