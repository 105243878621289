import { FunctionComponent } from 'react';

import { CardActions as MuiCardActions, CardActionsProps as MuiCardActionsProps } from '@mui/material';

interface ICardActionsProps extends MuiCardActionsProps {}
export const CardActions: FunctionComponent<ICardActionsProps> = (props: ICardActionsProps) => {

    return (
        <MuiCardActions {...props} />
    );
}