import React, { FunctionComponent } from 'react';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography
} from '@mui/material';

interface INoDataRowProps {
    columnCount: number;
}
export const NoDataTableRow: FunctionComponent<INoDataRowProps> = (props: INoDataRowProps) => {

    return (
        <MuiTableRow>
            <MuiTableCell
                colSpan={props.columnCount}
                align='center'
                style={{
                    borderBottom: 'none',
                    padding: '50px 0px'
                }}
            >
                <MuiTypography>
                    <i>No data available...</i>
                </MuiTypography>
            </MuiTableCell>
        </MuiTableRow>
    )
};