import { useState } from "react"

export const useBinaryToggle = (defaultValue?: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultValue ?? false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return {
    isOpen,
    setIsOpen,
    toggle
  }
}