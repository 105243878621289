import { FunctionComponent } from 'react';

import { Card as MuiCard, CardProps as MuiCardProps } from '@mui/material';

interface ICardProps extends MuiCardProps {}
export const Card: FunctionComponent<ICardProps> = (props: ICardProps) => {

    return (
        <MuiCard {...props} />
    );
}