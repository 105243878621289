import React from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { TDataDisplayType } from '@nxs/models';
import { camelCaseToUserText, formatAsDisplayType } from '@nxs/utils';

import { Checkbox } from './Checkbox';

export interface INonEditableRow<T> {
    dataKey?: keyof T;
    displayType?: TDataDisplayType;
    label?: string;
    element?: (data: T) => React.ReactNode;
}
interface INonEditableFieldsProps<T> {
    data: T;
    rows: INonEditableRow<T>[];
    sx?: SxProps<Theme>;
    rowBorders?: boolean;
}
export function NonEditableFields<T>(props: INonEditableFieldsProps<T>) {
    
    const { data, rows, sx, rowBorders } = props;

    const getRowValue = (row: INonEditableRow<T>): React.ReactNode => {
        if (row.element) {
            return row.element(data);
        } else if (!row.dataKey) {
            return null;
        } else if (!row.displayType) {
            return data[row.dataKey] as any;
        }

        const rowValue = data[row.dataKey] as any;
        if (row.displayType === 'checkbox') {
            return (
                <Checkbox readOnly checked={rowValue} sx={{padding: 0}} />
            )
        }

        return formatAsDisplayType(rowValue, row.displayType);
    }

    return (
        <Stack sx={sx}>
            {
                rows.map((row, index) => (
                    <Stack
                        key={index}
                        direction='row'
                        sx={{
                            padding: '15px 0px',
                            borderBottom: rowBorders === false ? 'none' : 'var(--border-light)'
                        }}
                        alignItems='center'
                        columnGap={2}
                    >
                        <Typography
                            fontWeight={500}
                            flex={1}
                            flexShrink={0}
                            variant='body2'
                        >
                            {row.label ?? camelCaseToUserText(row.dataKey as string)}
                        </Typography>

                        <Typography
                            flex={3}
                            variant='body2'
                        >
                            {getRowValue(row)}
                        </Typography>
                    </Stack>
                ))
            }
        </Stack>
    );
}
