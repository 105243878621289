import { TDataDisplayType } from 'models/shared';
import { formatAsCurrency } from './currencyUtils';
import { formatDate } from './dateUtils';
import { formatNumber } from './numberUtils';

export const formatAsDisplayType = (value: any, displayType: TDataDisplayType | undefined) => {
    if (displayType === 'currency') {
        return formatAsCurrency(value);
    } else if (displayType === 'date' || displayType === 'datetime') {
        return formatDate(value, displayType);
    } else if (typeof value === 'number' || displayType === 'number') {
        return formatNumber(value);
    }

    return value;
}