import { useQuery, useQueryClient, useMutation } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { HttpClientError, QueryKeys, IClientInvoice, IDefaultResponseMessage } from '@nxs/models';

const BASE_URL = '/v1';

export const useGetBillingCycleClientInvoices = (billingCycle: number) => {
    const { GET } = useHttpClient();

    return useQuery<IClientInvoice[], HttpClientError>([QueryKeys.ClientInvoices, billingCycle], () => {
        return GET<IClientInvoice[]>(`${BASE_URL}/billing-cycles/${billingCycle}/client-invoices`);
    }, {
        enabled: !!billingCycle && billingCycle > 0
    });
}

export const useGetClientInvoices = (clientId: string) => {
    const { GET } = useHttpClient();

    return useQuery<IClientInvoice[], HttpClientError>([QueryKeys.ClientInvoices, clientId], () => {
        return GET<IClientInvoice[]>(`${BASE_URL}/clients/${clientId}/client-invoices`);
    }, {
        enabled: !!clientId
    });
}

interface IApproveClientInvoice {
    clientId: string;
    invoiceId: string;
}
export const useApproveClientInvoiceMutation = () => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const approveClientInvoice = ({ invoiceId, clientId }: IApproveClientInvoice) => {
        return POST<IDefaultResponseMessage>(`/v1/clients/${clientId}/client-invoices/${invoiceId}/approve`);
    };

    return useMutation<IDefaultResponseMessage, HttpClientError, IApproveClientInvoice>(approveClientInvoice, {
        onSuccess: (response) => {
            showSnackbar(response.message!, response.boolean ? 'success' : 'warning');
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ClientInvoices] });
        }
    });
}