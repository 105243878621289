import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Alert, Stack, Typography, Breadcrumbs, Link, Container } from '@mui/material';

import { DEFAULT_ERROR_MESSAGE } from '@nxs/contexts';
import { PageLoader } from '@nxs/components';
import { useScreenSize } from '@nxs/hooks';

export interface IBreadCrumbOption {
    text: string;
    routerLink?: string;
    active?: boolean;
}
interface IDashboardPageWrapperProps {
    title: React.ReactNode;
    pageTitle: string;
    titleActions?: React.ReactNode;
    isPageLoadError?: boolean;
    isLoading?: boolean;
    breadCrumbs?: IBreadCrumbOption[];
    size?: 'full' | 'restrained';
    rowGap?: number;
    children: any;
}
export const DashboardPageWrapper: FunctionComponent<IDashboardPageWrapperProps> = (props: IDashboardPageWrapperProps) => {

    const { isMobile } = useScreenSize();

    const {
        title,
        pageTitle,
        children,
        titleActions,
        isPageLoadError,
        isLoading,
        breadCrumbs,
        rowGap
    } = props;

    return (
        <>
            <Helmet>
                <title>{pageTitle ?? title}</title>
            </Helmet>
            
            <Container maxWidth={props.size === 'restrained' ? 'lg' : false}>
                <Stack rowGap={6}>
                    <Stack rowGap={1}>
                        <Stack direction={isMobile ? 'column' : 'row'} rowGap={1} columnGap={3} alignItems='center'>
                            <Typography variant='h3'>{title}</Typography>
                            <Stack direction={isMobile ? 'column' : 'row'} columnGap={2} rowGap={1} flex={1} justifyContent='flex-end' alignItems='center' sx={{
                                width: isMobile ? '100%' : undefined
                            }}>
                                {titleActions}
                            </Stack>
                        </Stack>

                        {
                            breadCrumbs && breadCrumbs.length > 0 && (
                                <Breadcrumbs>
                                    {
                                        breadCrumbs.map((x, index) => x.active || !x.routerLink ? (
                                            <Typography
                                                color={x.active ? 'primary' : 'text.disabled'}
                                                key={index}
                                            >
                                                {x.text}
                                            </Typography>
                                        ) : (
                                            <RouterLink to={x.routerLink!} key={index}>
                                                <Link
                                                    underline="hover"
                                                    color="inherit"
                                                >
                                                    {x.text}
                                                </Link>
                                            </RouterLink>
                                        ))
                                    }
                                </Breadcrumbs>
                            )
                        }
                    </Stack>

                    {
                        isLoading && (
                            <PageLoader />
                            )
                        }

                    {
                        isPageLoadError ? (
                            <Alert severity='error'>
                                {DEFAULT_ERROR_MESSAGE}
                            </Alert>
                        ) : (
                            <Stack rowGap={rowGap ?? 5}>
                                {children}
                            </Stack>
                        )
                    }
                </Stack>
            </Container>
        </>
    )
};