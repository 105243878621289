import { useMutation, useQuery, useQueryClient } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { HttpClientError, IDefaultResponseMessage, IProjectTask, QueryKeys } from '@nxs/models';

const BASE_URL = '/v1/project-tasks';

export const useGetProjectTasks = (projectId: string, billingCycle: number, includeZeroHourTasks: boolean) => {
    const { GET } = useHttpClient();

    return useQuery<IProjectTask[], HttpClientError>([QueryKeys.ProjectTasks, projectId, billingCycle, includeZeroHourTasks], () => {
        return GET<IProjectTask[]>(`${BASE_URL}/${projectId}/${billingCycle}`, { includeZeroHourTasks });
    }, {
        enabled: !!projectId && !!billingCycle && billingCycle > 0
    });
}

interface IRefreshProjectTasks {
    projectId: string;
    billingCycle: number;
}
export const useRefreshProjectTasksMutation = () => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refreshTasks = (request: IRefreshProjectTasks) => {
        return POST<IDefaultResponseMessage>(`${BASE_URL}/${request.projectId}/${request.billingCycle}/refresh`);
    }

    return useMutation<IDefaultResponseMessage, HttpClientError, IRefreshProjectTasks>(refreshTasks, {
        onSuccess: (_, request) => {
            showSnackbar('Project tasks successfully refreshed');
            queryClient.invalidateQueries([QueryKeys.ProjectTasks, request.projectId, request.billingCycle]);
            queryClient.invalidateQueries([QueryKeys.ProjectBillingCycles, request.projectId]);
        }
    });
}