import { buildQueryParamString } from "@nxs/contexts";

export const camelCaseToUserText = (input: string): string => (
    `${input[0].toUpperCase()}${input.replace(/([A-Z](?=[a-z]+)|[A-Z]+(?![a-z]))/g, ' $1').slice(1)}`
);

export const splitByCapitalLetter = (input: string) => input.match(/[A-Z][a-z]+/g)?.join(' ');

export const formatRoute = (route: string, routeParams: Record<string, string>, queryParams?: Record<string, string>): string => {
    let formattedRoute = `${route}`;
  
    Object.keys(routeParams).forEach((key) => {
      formattedRoute = formattedRoute.replace(`:${key}`, encodeURIComponent(routeParams[key]));
    });
  
    let queryParamsString = '';
    if (queryParams) {
      queryParamsString = buildQueryParamString(queryParams);
    }
  
    return `${formattedRoute}${queryParamsString}`;
}