import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import { useFormContext } from './Form.context';
import { camelCaseToUserText } from '@nxs/utils';

interface IFormTextInputProps<T> extends Omit<TextFieldProps, 'label' | 'type' | 'name' | 'disabled' | 'value' | 'onChange' | 'InputProps' | 'error'> {
    name: keyof T;
    label?: string;
    type?: 'text' | 'number' | 'email' | 'tel' | 'password' | 'color';
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
}
export function FormTextInput<T>(props: IFormTextInputProps<T>) {
    const {
        label,
        type,
        name,
        disabled,
        startIcon,
        endIcon,
        ...baseInputProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors
    } = useFormContext<T>();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        handleFormChange(name, newValue);
    };

    return (
        <TextField
            {...baseInputProps}
            label={label ?? camelCaseToUserText(String(name))}
            name={String(name)}
            type={type ?? 'text'}
            value={formData[name]}
            disabled={disabled || formDisabled}
            onChange={handleInputChange}
            error={formErrors[name]}
            InputProps={{
                startAdornment: !startIcon ? null : (
                    <InputAdornment position='start'>
                        {startIcon}
                    </InputAdornment>
                ),
                endAdornment: !endIcon ? null : (
                    <InputAdornment position='end'>
                        {endIcon}
                    </InputAdornment>
                )
            }}
        />
    )
};