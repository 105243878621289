import { Dayjs } from "dayjs";

export type TColor = 'primary' | 'secondary' | 'tertiary' | 'error' | 'success' | 'warning' | 'info';
export type TChartType = 'line' | 'area' | 'bar' | 'pie';
export type THttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type TDateFormat = 'datetime' | 'date'
export type TDataDisplayType = TDateFormat | 'currency' | 'number' | 'checkbox';

export interface IReducerAction<T> {
    type: T;
    payload?: any;
}

export interface IAppSettings {
    apiBaseUrl: string;
    environment?: 'local' | 'prod';
    buildVersion?: string;
}

export interface IPortalUser {
    id: string;
    email: string;
    name: string;
    surname: string;
    enabled: boolean;
    timeStamp: Dayjs   
}

export interface IMenuItem {
    icon?: JSX.Element;
    text?: string;
    content?: React.ReactNode;
    value?: string | number;
    isDivider?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    routerLink?: string;
    data?: any;
}

export interface IDefaultResponseMessage {
    message?: string;
    boolean?: boolean;
}

export interface IInvoiceItemCode {
    id: string;
    code: string;
    unitPrice: number;
}

interface IHttpErrorResponse {
    requestUrl: string;
    requestMethod: THttpMethod;
    statusCode: number;
    errorMessages: string[];
    rawErrors?: any;
}
export class HttpClientError extends Error {

    public statusCode: number;
    public firstErrorMessage: string;
    public errorMessages: string[];

    public errorResponse: IHttpErrorResponse;

    constructor(errorResponse: IHttpErrorResponse) {
        super(`[${errorResponse.statusCode}] error while performing [${errorResponse.requestMethod} ${errorResponse.requestUrl}]`, {
            cause: errorResponse.rawErrors
        });

        Object.setPrototypeOf(this, HttpClientError.prototype);

        this.errorResponse = errorResponse;
        this.statusCode = errorResponse.statusCode;
        this.firstErrorMessage = errorResponse.errorMessages[0];
        this.errorMessages = errorResponse.errorMessages;
    }
}