const numberFormatter = new Intl.NumberFormat('en-NZ');

const percentageFormatter = new Intl.NumberFormat('en-NZ', {
    style: 'percent',
    maximumFractionDigits: 6
});

export const formatNumber = (amount: number): string => 
    numberFormatter.format(amount);

export const formatPercentage = (amount: number, rounding?: 'none' | 'ceiling' | 'floor'): string => {
    let amountToFormat = amount;
    if (rounding === 'ceiling') {
        amountToFormat = Math.ceil(amountToFormat);
    } else if (rounding === 'floor') {
        amountToFormat = Math.floor(amountToFormat);
    }
    return percentageFormatter.format(amountToFormat / 100);
}

export const roundNumber = (number: number, decimalPlaces?: number) => {
    const roundingFactor = Math.pow(10, decimalPlaces ?? 0);
    return Math.round(number * roundingFactor) / roundingFactor;
}

export const convertMinutesToHours = (minutes: number): number =>
    Math.ceil((minutes / 60) * 10) / 10;