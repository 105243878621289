import { FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-nz';

import {
  AppSettingsContextProvider,
  HttpClientContextProvider,
  SnackbarContextProvider,
  AuthContextProvider,
  ConfirmContextProvider,
} from '@nxs/contexts';

import AppTheme from './App.theme';
import AppRouter from './App.router';

const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 5 // 5 minutes
    },
  }
});

const App: FunctionComponent = () => {
  return (
    <AppTheme>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-nz'>
        <SnackbarContextProvider>
          <QueryClientProvider client={QUERY_CLIENT}>
            <AppSettingsContextProvider>
              <HttpClientContextProvider>
                  <AuthContextProvider>
                    <ConfirmContextProvider>

                      <AppRouter />

                    </ConfirmContextProvider>
                  </AuthContextProvider>
              </HttpClientContextProvider>
            </AppSettingsContextProvider>
          </QueryClientProvider>
        </SnackbarContextProvider>
      </LocalizationProvider>
    </AppTheme>
  );
}

export default App;
