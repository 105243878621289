import { PieChart as RechartsPieChart, Pie as RechartsPie, Cell as RechartsCell } from 'recharts';

import { formatAsDisplayType } from '@nxs/utils';
import { TColor, TDataDisplayType } from '@nxs/models';
import { getColor } from '@nxs/utils';

import { IGenericChartProps } from './models';

interface ICustomLabelProps {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number
}

export function PieChart<T>(props: IGenericChartProps<T>) {

    const {
        data,
        width,
        height,
        children,
        dataKeys,
        disabledKeys,
        chartColor
    } = props;

    const cells: Array<{ label: string, value: any, color: TColor | string, key: string, displayType: TDataDisplayType | undefined }> = dataKeys
        .filter(x => disabledKeys.indexOf(x.dataKey) < 0)
        .map(x => ({
            label: x.legendName,
            value: data.map(y => y[x.dataKey] as any).reduce((a, b) => a + b),
            color: x.color,
            key: x.dataKey as any,
            displayType: x.displayType
        }));

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: ICustomLabelProps) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const targetCell = cells[index];
        const dataValue = formatAsDisplayType(targetCell.value, targetCell.displayType);
    
        return (
            <text x={x} y={y} fontSize='13px' fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {dataValue}
            </text>
        );
    };

    return (
        <RechartsPieChart
            width={width}
            height={height}
            className='chart'
        >
            {children}
            <RechartsPie
                dataKey='value'
                data={cells}
                fill={chartColor ? getColor(chartColor) : undefined}
                label={renderCustomizedLabel}
                labelLine={false}
                animationDuration={1000}
                animationBegin={0}
                cx="50%"
                cy="50%"
                outerRadius={'95%'}
            >
                {
                    cells.map(cell => (
                        <RechartsCell
                            key={cell.key}
                            fill={getColor(cell.color)}
                            stroke={chartColor ? getColor(chartColor) : undefined}
                            strokeWidth={5}
                        />
                    ))
                }
            </RechartsPie>
        </RechartsPieChart>
    )
};