import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { ICreateClientRequest, QueryKeys, IClient, IUpdateClientRequest, HttpClientError } from '@nxs/models';

export const useGetClient = (clientId: string | undefined) => {
    const { GET } = useHttpClient();

    return useQuery<IClient, HttpClientError>([QueryKeys.Clients, clientId], () => {
        return GET<IClient>(`/v1/clients/${clientId}`);
    }, {
        enabled: !!clientId
    });
}

export const useGetClients = () => {
    const { GET } = useHttpClient();

    return useQuery<IClient[], HttpClientError>([QueryKeys.Clients], () => {
        return GET<IClient[]>(`/v1/clients`);
    });
}

export const useUpdateClientMutation = () => {
    const { PUT } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const updateClient = (request: IUpdateClientRequest) => {
        return PUT<IClient>(`/v1/clients/${request.id}`, request);
    };

    return useMutation<IClient, HttpClientError, IUpdateClientRequest>(updateClient, {
        onSuccess: (client) => {
            showSnackbar('Client successfully updated');
            queryClient.invalidateQueries([QueryKeys.Clients]);
            queryClient.invalidateQueries([QueryKeys.Clients, client.id]);

            client.projects.forEach(element => {
                queryClient.invalidateQueries([QueryKeys.Projects, element.id]);
            });
        }
    });
}

export const useCreateClientMutation = (autoNavigate?: boolean) => {
    const { POST } = useHttpClient();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const createClient = (request: ICreateClientRequest) => {
        return POST<IClient>('/v1/clients', request);
    };

    return useMutation<IClient, HttpClientError, ICreateClientRequest>(createClient, {
        onSuccess: (client) => {
            showSnackbar('Client successfully created');
            if (autoNavigate) {
                navigate(`/dashboard/clients/${client.id}`);
            }
            queryClient.invalidateQueries([QueryKeys.Clients]);
        }
    });
}