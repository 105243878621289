import React, { FunctionComponent, Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import { AuthGuard, PageLoader } from '@nxs/components';

const LazyLoader = (Component: React.LazyExoticComponent<FunctionComponent>) => (props: any) => (
    <Suspense fallback={<PageLoader />}>
        <Component {...props} />
    </Suspense>
)

const LoginPage = LazyLoader(lazy(() => import('./pages/LoginPage')));
const DashboardPage = LazyLoader(lazy(() => import('./pages/DashboardPage')));
const BillingCyclesPage = LazyLoader(lazy(() => import('./pages/BillingCyclesPage')));
const ClientListPage = LazyLoader(lazy(() => import('./pages/ClientListPage')));
const ClientPage = LazyLoader(lazy(() => import('./pages/ClientPage')));
const ClientProjectListPage = LazyLoader(lazy(() => import('./pages/ClientProjectListPage')));
const ProjectListPage = LazyLoader(lazy(() => import('./pages/ProjectListPage')));
const ProjectPage = LazyLoader(lazy(() => import('./pages/ProjectPage')));
const ClientInvoicesListPage = LazyLoader(lazy(() => import('./pages/ClientInvoicesListPage')));
const RecurringExpensesListPage = LazyLoader(lazy(() => import('./pages/RecurringExpensesListPage')));
const TravelLogListPage = LazyLoader(lazy(() => import('./pages/TravelLogListPage')));

const AppRouter: FunctionComponent = () => {

    const routes = createBrowserRouter([
        {
            element: (
                <LoginPage />
            ),
            index: true
        },
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardPage />
                </AuthGuard>
            ),
            children: [
                {
                    path: 'billing-cycles',
                    element: (
                        <BillingCyclesPage />
                    )
                },
                {
                    path: 'clients',
                    element: (
                        <ClientListPage />
                    )
                },
                {
                    path: 'clients/:clientId',
                    element: (
                        <ClientPage />
                    )
                },
                {
                    path: 'clients/:clientId/projects',
                    element: (
                        <ClientProjectListPage />
                    )
                },
                {
                    path: 'clients/:clientId/projects/:projectId',
                    element: (
                        <ProjectPage />
                    )
                },
                {
                    path: 'projects',
                    element: (
                        <ProjectListPage />
                    )
                },
                {
                    path: 'projects/:projectId',
                    element: (
                        <ProjectPage />
                    )
                },
                {
                    path: 'client-invoices',
                    element: (
                        <ClientInvoicesListPage />
                    )
                },
                {
                    path: 'recurring-expenses',
                    element: (
                        <RecurringExpensesListPage />
                    )
                },
                {
                    path: 'travel-log',
                    element: (
                        <TravelLogListPage />
                    )
                }
            ]
        },
        {
            path: '*',
            element: (
                <Navigate to='/' replace />
            )
        }
    ]);

    return (
        <RouterProvider router={routes} />
    );
};

export default AppRouter;