import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

import { useFormContext } from './Form.context';

import { Dayjs } from 'dayjs';
import { camelCaseToUserText } from '@nxs/utils';

type TDatePickerValue = Dayjs | null | undefined;

interface IFormDateInputProps<T> extends Omit<DatePickerProps<Dayjs>, 'name' | 'label' | 'disabled'> {
    name: keyof T;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    modifier?: 'startOfDay' | 'endOfDay';
}
export function FormDateInput<T>(props: IFormDateInputProps<T>) {

    const {
        name,
        label,
        disabled,
        required,
        modifier,
        ...datePickerProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors
    } = useFormContext<T>();

    const handleInputChange = (value: TDatePickerValue) => {
        let newValue = value;
        if (newValue && modifier === 'startOfDay') {
            newValue = newValue.startOf('day');
        } else if (newValue && modifier === 'endOfDay') {
            newValue = newValue.endOf('day');
        }

        handleFormChange(name, newValue);
    };

    return (
        <DatePicker
            {...datePickerProps}
            label={label ?? camelCaseToUserText(String(name))}
            format={props.format ?? 'DD MMM YYYY'}
            value={formData[name] as TDatePickerValue}
            onChange={handleInputChange}
            slotProps={{
                textField: {
                    error: formErrors[name],
                    required: required
                }
            }}
            disabled={disabled || formDisabled}
        />
    );
};