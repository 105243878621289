import { useMutation, useQuery } from "react-query";

import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { HttpClientError, IAzureDevOpsAvailableProject, IDefaultResponseMessage, QueryKeys } from '@nxs/models';
import { DEFAULT_ERROR_MESSAGE } from "@nxs/contexts";

const BASE_URL = '/v1/ado';

export const useAzureDevOpsAvailableProjects = () => {
    const { GET } = useHttpClient();

    return useQuery<IAzureDevOpsAvailableProject[], HttpClientError>([QueryKeys.AzureDevOpsAvailableProjects], () => {
        return GET<IAzureDevOpsAvailableProject[]>('/v1/ado/available-projects');
    });
}

export const useIsAzureDevOpsConfigured = () => {
    const { GET } = useHttpClient();

    return useQuery<IDefaultResponseMessage, HttpClientError>([QueryKeys.AzureDevOpsIsConfigured], () => {
        return GET<IDefaultResponseMessage>(`${BASE_URL}/is-configured`);
    });
}

export const useStartAzureDevOpsAuthFlowMutation = () => {
    const { buildUrl } = useHttpClient();
    const { showError } = useSnackbar();

    const startAuthFlow = async () => {
        await new Promise<boolean>((resolve) => {
            const redirectUrl = window.location.href;
            window.location.href = buildUrl(`${BASE_URL}/start-auth-flow`, {
                redirectUrl
            });
            resolve(true)
        });
    };

    return useMutation(startAuthFlow, {
        onError: (err) => {
            console.error('useStartAzureDevOpsAuthFlowMutation', err);
            showError(DEFAULT_ERROR_MESSAGE)
        }
    });
}