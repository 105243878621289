import React from 'react';

import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    SelectProps,
    MenuItem,
    InputAdornment,
    SelectChangeEvent,
    ListItemIcon,
    ListItemText,
    Divider
} from '@mui/material';

import { IMenuItem } from '@nxs/models';
import { useFormContext } from './Form.context';
import { ProgressSpinner } from '../ProgressSpinner';
import { camelCaseToUserText } from '@nxs/utils';

export interface IFormSelectInputProps<T> extends Omit<SelectProps, 'label' | 'name'> {
    name: keyof T;
    options: IMenuItem[];
    label?: string;

    startIcon?: JSX.Element;
    isLoading?: boolean;
}
export function FormSelectInput<T>(props: IFormSelectInputProps<T>) {

    const {
        label,
        name,
        options,
        startIcon,
        isLoading,
        ...baseInputProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors
    } = useFormContext<T>();

    const handleSelectionChange = (e: SelectChangeEvent<unknown>) => {
        const newValue = e.target.value;
        handleFormChange(name, newValue);
    };

    const getIcon = (icon: JSX.Element) => {
        const color = props.error ? 'error' : '';
        return React.cloneElement(icon, {
            color
        });
    };

    const buildStartIcon = () => !startIcon ? null : (
        <InputAdornment position='start'>
            {getIcon(startIcon)}
        </InputAdornment>
    );

    const buildEndIcon = () => !isLoading ? null : (
        <InputAdornment position='end'>
            <ProgressSpinner size={20} />
        </InputAdornment>
    );

    return (
        <FormControl fullWidth error={props.error} required={props.required} margin={props.margin}>
            <InputLabel
                id={String(name)}
                size={props.size === 'small' ? 'small' : 'normal'}
            >
                {label ?? camelCaseToUserText(String(name))}
            </InputLabel>
            <MuiSelect
                {...baseInputProps}
                labelId={String(name)}
                value={formData[name]}
                label={label ?? camelCaseToUserText(String(name))}
                onChange={handleSelectionChange}
                error={formErrors[name]}
                disabled={props.disabled || formDisabled || isLoading}
                name={String(name)}
                startAdornment={buildStartIcon()}
                endAdornment={buildEndIcon()}
                renderValue={(selected: any) => {
                    const matchingItem = options!.find(x => (x.value ?? x.text) === selected)
                    return matchingItem?.text ?? matchingItem?.content;
                }}
            >
                {
                    options!.filter(x => !x.hidden).map((option, index) => option.isDivider ? (
                        <Divider key={index} />
                    ) : (
                        <MenuItem
                            value={option.value ?? option.text}
                            key={option.value ?? option.text ?? index}
                        >
                            {
                                option.icon && (
                                    <ListItemIcon>
                                        {option.icon}
                                    </ListItemIcon>
                                )
                            }
                            {option.content ?? (
                                <ListItemText>{option.text}</ListItemText>
                            )}
                        </MenuItem>
                    ))
                }
            </MuiSelect>
        </FormControl>
    )
};
