import { FunctionComponent } from 'react';

import { ProgressSpinner } from './core';
import { Stack } from '@mui/material';

export const PageLoader: FunctionComponent = () => {
    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
                backgroundColor: 'rgba(var(--color-background-rgb), 0.6)',
                zIndex: '999'
            }}
            className='absolute-full-page'
        >
            <ProgressSpinner color='primary' />
        </Stack>
    )
};