import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@nxs/hooks';

interface IAuthGuardProps {
    children: any;
}
export const AuthGuard: FunctionComponent<IAuthGuardProps> = (props: IAuthGuardProps) => {
    const { children } = props;

    const { isAuthenticated, isInitialized } = useAuth();

    if (!isInitialized) {
        return null;
    } else if (!isAuthenticated) {
        return <Navigate to='/' />
    }

    return (
        <>
            {children}
        </>
    )
};