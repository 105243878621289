import { FunctionComponent } from 'react';

import { CardContent as MuiCardContent, CardContentProps as MuiCardContentProps } from '@mui/material';

interface ICardContentProps extends MuiCardContentProps {}
export const CardContent: FunctionComponent<ICardContentProps> = (props: ICardContentProps) => {

    return (
        <MuiCardContent {...props} />
    );
}