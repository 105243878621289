import { useMutation, useQuery } from "react-query";

import { DEFAULT_ERROR_MESSAGE } from '@nxs/contexts';
import { useHttpClient, useSnackbar } from '@nxs/hooks';
import { HttpClientError, IDefaultResponseMessage, IXeroContact, IXeroInvoiceItem, QueryKeys } from '@nxs/models';

const BASE_URL = '/v1/xero';

export const useXeroAvailableClients = () => {
    const { GET } = useHttpClient();

    return useQuery<IXeroContact[], HttpClientError>([QueryKeys.XeroAvailableClients], () => {
        return GET<IXeroContact[]>(`${BASE_URL}/available-clients`);
    });
}

export const useIsXeroConfigured = () => {
    const { GET } = useHttpClient();

    return useQuery<IDefaultResponseMessage, HttpClientError>([QueryKeys.XeroIsConfigured], () => {
        return GET<IDefaultResponseMessage>(`${BASE_URL}/is-configured`);
    });
}

export const useXeroInvoiceItemCodes = () => {
    const { GET } = useHttpClient();

    return useQuery<IXeroInvoiceItem[], HttpClientError>([QueryKeys.XeroInvoiceItemCodes], () => {
        return GET<IXeroInvoiceItem[]>(`${BASE_URL}/invoice-item-codes`);
    });
}

export const useStartXeroAuthFlowMutation = () => {
    const { buildUrl } = useHttpClient();
    const { showError } = useSnackbar();

    const startAuthFlow = async () => {
        await new Promise<boolean>((resolve) => {
            const redirectUrl = window.location.href;
            window.location.href = buildUrl(`${BASE_URL}/start-auth-flow`, {
                redirectUrl
            });
            resolve(true)
        });
    };

    return useMutation(startAuthFlow, {
        onError: (err) => {
            console.error('useStartXeroAuthFlowMutation', err);
            showError(DEFAULT_ERROR_MESSAGE)
        }
    });
}